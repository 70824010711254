import React from "react";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import {
  Button,
  Card,
  Table,
  Row,
  Col,
  Toast
} from "react-bootstrap";
import { useState, useEffect } from "react";
import { ModalList } from "components/Modal/ModalList";
import axios from "axios";
import { Link } from "react-router-dom";
import EncuestasListSincronizadas from "./EncuestasListSincronizadas";
import {BrowserView, MobileView} from 'react-device-detect';

export default function EncuestasList() {

	const [mostrarToast, setMostrarToast] = useState(false);
	const toggleMostrarToast = () => setMostrarToast(!mostrarToast);
	const [toastBody, setBodyToast] = useState("");

  	const [modalShow, setModalShow] = useState(false);
  	const [encuestaIndex, setEncuestaIndex] = useState(false);
  	const [loading, setLoading] = useState(""); 
  	const [candidatos, setCandidatos] = useState("[]");
 
  	const [key, setKey] = useState('pendientes');
  	const preguntaEdadId = "90";
  	const opcionesEdadId = new Array ({"0": "201", "2": "202", "3": "203", "4": "204"});

	const preguntaFamiliarId = "91";
	const opcionesFamiliarId = new Array ({"0": "205", "3": "206", "5": "207"});
	
	const preguntaPerimetroId = "92";
	const opcionesPerimetroId = new Array ({"0": "208", "4": "209"});

	const preguntaActividadId = "93";
	const opcionesActividadId = new Array ({"0": "214", "2": "215"});
		
	const preguntaVerdurasId = "94";
	const opcionesVerdurasId = new Array ({"0": "216", "1": "217"});
	
	const preguntaMedicamentoHipertensionId = "95";
	const opcionesMedicamentoHipertensionId = new Array ({"0": "218", "2": "219" });
	
	const preguntaGlucosaId = "96";
	const opcionesGlucosaId = new Array ({"0": "220", "5": "221"});
	
	const preguntaMasaId = "97";
	const opcionesMasaId = new Array ({"0": "222", "1": "223" , "2":"224"});
						
	const totalId = "98";
    const idEfector = "99";    
	const presionSistolicaId = "102";
	const presionDiastolicaId = "103";

  //Obtenemos las encuestas Cargadas
  //console.log(JSON.parse(localStorage.getItem("datos")));

  useEffect(() => {
    localStorage.getItem('datos')
  }, []);
  let arrayDatos = JSON.parse(localStorage.getItem("datos")).encuestas;
  const mostrarDatos = (isMobile) => {
    
    return arrayDatos.map(( listValue, index ) => {
      if(listValue.estado !== "SINCRONIZADO" ) {

        let htmlResultadoSincronizado;
        if (listValue.estado === "PENDIENTE") {
          htmlResultadoSincronizado = <div style={{display: 'flex', justifyContent: 'flex-end'}}><p className="text-end">PENDIENTE DE SINCRONIZAR</p></div>
        }

        if (listValue.estado === "PENDIENTE_CANDIDATO_SELECCIONADO" || listValue.estado === "PENDIENTE_CANDIDATO_A_SELECCIONAR") {
          htmlResultadoSincronizado = <Button variant={(listValue.estado === "PENDIENTE_CANDIDATO_SELECCIONADO")?"success":"warning"} 
                  onClick={() => { 
                  setEncuestaIndex(listValue.id);
                  setCandidatos(JSON.stringify( listValue.candidatos));
                  setModalShow(true);
                  }}>
                  {
              (listValue.estado === "PENDIENTE_CANDIDATO_SELECCIONADO")? "MODIFICAR SELECCION"
              : "CONFIRMAR PACIENTE" }
            </Button>
            if (listValue.estado === "PENDIENTE_CANDIDATO_A_SELECCIONAR") {
              <Row>
                  <Col>
                      <p>SISSE detecto un paciente con datos personales similares. Presione el boton de arriba para confirmar.</p>
                  </Col>
              </Row>
            }
        }

        if (listValue.estado === "FALLIDO") {
          htmlResultadoSincronizado = <div style={{display: 'flex', justifyContent: 'flex-end'}}><p className="text-end">FALLIDA</p></div>
        }

        return (
          (!isMobile)?
            <tr key={index}>
              <td className="d-m-4" id="table-encuesta">{listValue.persona.nombre} {listValue.persona.apellido}</td>
              <td id="table-encuesta">{listValue.persona.documento}</td>
              <td id="table-encuesta">{listValue.fecha.split(' ')[0]}</td>
              <td>{htmlResultadoSincronizado}</td>
          </tr>
          :
            <Card key={index} className="border-primary"  style={{ border:'1px #ced4da solid', marginBottom: '10px'}}>
              <Card.Header className="bg-light "  style={{backgroundColor: '#ced4da', border:'1px #adb5bd solid'}}> 
                <h6>{listValue.persona.nombre} {listValue.persona.apellido}</h6>
              </Card.Header>
              <Card.Body>
                <Row>
                  <Col className="col-8">
                    <p><b>DNI: {listValue.persona.documento}</b></p>
                    <p><b>F. Nac.: {listValue.persona.fecha_nacimiento}</b></p>
                    <p><b>F. Encuesta: { listValue.fecha.split(' ')[0] }</b></p>
                  </Col>
                  <Col className="col-4"> 
                    <div className="shadow-none p-2 mb-5 bg-body-tertiary text-center rounded" style={{border: '1px grey solid'}}>
                    Puntos <span style={{fontSize: '1em'}} className="badge text-bg-light" >{listValue.total}</span>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    {/* en el local storage podemos tener guardado (a razon de diferentes versiones): user o user_nombre y user_apellido o nada */}                    
                    {(() => {
                        if (typeof(listValue.user_nombre) !== "undefined") {
                            <div>
                                Usuario: <b>{listValue.user_apellido + ", " + listValue.user_nombre}</b><br></br>
                                Efector: <b>{listValue.nombre_efector}</b>
                            </div>
                        } else {
                            if (typeof(listValue.user) !== "undefined") {
                                <div>
                                    Usuario: <b>{listValue.user.apellido + ", " + listValue.user.nombre}</b><br></br>
                                    Efector: <b>{listValue.nombre_efector}</b>
                                </div>
                            } else {
                                <div>
                                    Id de Usuario: <b>{listValue.user_id}</b>&nbsp;
                                    Id de Efector: <b>{listValue.id_efector}</b>
                                </div>
                            }
                        }
                    })()}
                  </Col>
                </Row>
                
                <hr className="dark horizontal my-0 mt-2 mb-2" />

               <div className="float-end">{htmlResultadoSincronizado}</div>
              </Card.Body>
             
            </Card>
          
        );
      }
  })
 }
 //const datosR = mostrarDatos();
 //setTableContent(() => mostrarDatos);

 function dataURLToBlob(dataURL) {
	var BASE64_MARKER = ';base64,';

	if (dataURL.indexOf(BASE64_MARKER) == -1) {
		 var parts = dataURL.split(',');
		 var contentType = parts[0].split(':')[1];
		 var raw = decodeURIComponent(parts[1]);

		 return new Blob([raw], {type: contentType});
	}

	var parts = dataURL.split(BASE64_MARKER);
	var contentType = parts[0].split(':')[1];
	var raw = window.atob(parts[1]);
	var rawLength = raw.length;

	var uInt8Array = new Uint8Array(rawLength);

	for (var i = 0; i < rawLength; ++i) {
		 uInt8Array[i] = raw.charCodeAt(i);
	}

	return new Blob([uInt8Array], {type: contentType});
}

const sincronizar = () => {
 
	setLoading(true);
  	const token = localStorage.getItem("token");
  	const config = {
		headers: { Authorization: `Bearer ${token}`, "Content-Type": "multipart/form-data" }
  	};
  
	let formData = new FormData();

    // esto es para quitar la propiedad user, que tenia demasiados datos que se enviaban por post
    // datos que no se utilizaban, ya no se guarda esta propiedad en el localstorage
    // pero para las encuestas ya creadas hay que quitarlo
    arrayDatos = arrayDatos.map(( listValue ) => {
		if (typeof (listValue.user) != "undefined") {
            listValue.user_nombre = listValue.user.nombre;
            listValue.user_apellido = listValue.user.apellido;
      	    delete listValue.user;
		}
        return listValue;
    });
    
    var cantidad = 0;
  	let toSend = arrayDatos.filter((listValue) => {
		
		if (listValue.persona.foto) {
			const blob = dataURLToBlob(listValue.persona.foto);
			formData.append("foto_" + listValue.id, blob);
      	    delete listValue.persona.foto;
		}

		if ((listValue.estado === 'PENDIENTE' || listValue.estado === 'PENDIENTE_CANDIDATO_SELECCIONADO') && cantidad <= 10) {

            cantidad ++;
			listValue.valores = [
				{"preguntaId": preguntaEdadId, "valor": opcionesEdadId[0][listValue.edad]},
				{"preguntaId": preguntaFamiliarId, "valor": opcionesFamiliarId[0][listValue.familia]},
				{"preguntaId": preguntaPerimetroId, "valor": opcionesPerimetroId[0][listValue.perimetro]},
				{"preguntaId": preguntaActividadId, "valor": opcionesActividadId[0][listValue.actividad]},
				{"preguntaId": preguntaVerdurasId, "valor": opcionesVerdurasId[0][listValue.verdura]},
				{"preguntaId": preguntaMedicamentoHipertensionId, "valor": opcionesMedicamentoHipertensionId[0][listValue.medicamento_hipertension]},
				{"preguntaId": preguntaGlucosaId, "valor": opcionesGlucosaId[0][listValue.glucosa]},
				{"preguntaId": preguntaMasaId, "valor": opcionesMasaId[0][listValue.masa]},
                {"preguntaId": totalId, "valor": listValue.total},
                {"preguntaId": idEfector, "valor": typeof(listValue.id_efector) === 'undefined' ? 0 : listValue.id_efector},
                {"preguntaId": presionSistolicaId, "valor": listValue.presion1},
                {"preguntaId": presionDiastolicaId, "valor": listValue.presion2}
			];
			listValue.formId = 26;
			return listValue;
		}
	});

	formData.append("document", JSON.stringify(toSend));

    /*arrayDatos = arrayDatos.slice(0, 15).map(( listValue ) => {
        if (listValue.estado !== "SINCRONIZADO") {
            return listValue;
        }        
    });*/

  	axios 
		.post(process.env.REACT_APP_API_URL + 'sync', formData, config)
		.then((response) => {
			
            var cantFallidas = 0;
            var cantSeleccionarCandidato = 0;
            arrayDatos = arrayDatos.map(( listValue ) => {
                let item = response.data.filter(function(item) {
                    return item.id === listValue.id;
                });

                if (item.length === 1) {
                    if (item[0].estado === true) {
                        listValue.estado = "SINCRONIZADO";
                    } else {
                        if (item[0].candidatos) {
                            listValue.candidatos = item[0].candidatos
                            listValue.estado = "PENDIENTE_CANDIDATO_A_SELECCIONAR";

                            cantSeleccionarCandidato = cantSeleccionarCandidato + 1;
                        } else {
                            cantFallidas = cantFallidas + 1;
                            listValue.candidatos = [];
                            listValue.estado = "FALLIDO";
                        }
                
                        listValue.msg = item[0].msg;                        
                    }
                }

                if (listValue.estado !== "SINCRONIZADO") {
                    
                }
                return listValue;
            });

            var msg = "Encuestas sincronizadas correctamente";
            if (cantFallidas > 0) {
                msg = "Hubo un error al sincronizar algunas encuestas, por favor intente mas tarde";
            }      
            if (cantSeleccionarCandidato > 0) {
                msg = "Para algunas de las encuestas es necesario confirmar la correspondencia con la persona en SISSE";
            }
            setBodyToast(msg);
            toggleMostrarToast(true);
			
			localStorage.setItem("datos", '{"encuestas": ' + JSON.stringify(arrayDatos) + '}');    
		})
       .catch((error) => {
            setBodyToast("Hubo un error al sincronizar algunas encuestas, por favor intente mas tarde");
            setLoading(false);
        /* if (error.response === 422){
           setErrores(error.response.data.errores);
         }*/
       })
       .finally(() => {        
            setLoading(false);
       });
     };
  return (
    <>
      <Row>
        <ModalList encuestaindex={encuestaIndex} candidatos={candidatos}  show={modalShow} onHide={ () => setModalShow(false)} />
      </Row>
      <Card>
        <Card.Header >
          <Card.Title className="">
            <Row className="mb-4">
                <Col className="col-md-6 text-left">
                    Encuestas en este dispositivo/computadora
                </Col>
                <Col className="d-grid justify-content-md-end">
                    <Link className="btn btn-sm btn-dark" to="/admin/nueva-encuesta">
                    <i className="fas fa-plus"></i> Encuesta</Link>
                </Col>                
            </Row>        
          </Card.Title>                
        </Card.Header>
        <Card.Body style={{paddingTop:"0px"}}>   
          <Tabs id="controlled-tab-example"  activeKey={key} onSelect={(k) => setKey(k)} className="mb-3">
            <Tab eventKey="pendientes"  title="Pendientes">
              <div className="tab-content" id="myTabContent">
                <div className="tab-pane fade show active" id="pendientes" role="tabpanel" aria-labelledby="pendientes-tab">
                  <Row>
                    <Col md="12"> 
                      <div className="d-flex justify-content-end align-items-end ">
                        <Button disabled={loading} variant="primary" onClick={() => {                        
                            sincronizar()
                          }
                          }> {loading ? (<div className="spinner-grow text-light" role="status">
                            <span className="visually-hidden">Cargando...</span>
                          </div>) : (<i className="fas fa-sync"></i>)} Sincronizar                          
                        </Button>
                      </div>              
                      <BrowserView>
                        <Table className="table align-items-center justify-content-center mb-0">
                          <thead>
                          <tr>
                            <th className="border-0">Nombre</th>
                            <th className="border-0">DNI</th>
                            <th className="border-0">F. Nacimiento</th>
                            <th className="border-0">Fecha</th>
                          </tr>
                          </thead>
                          <tbody>                  
                          {mostrarDatos(false)}                
                          </tbody>
                        </Table>
                      </BrowserView>
                      <MobileView>
                        {mostrarDatos(true)}  
                      </MobileView>
                    </Col>
                  </Row>
                </div>          
              </div>      
            </Tab>
            <Tab eventKey="sincronizadas" title="Sincronizadas">
              Dirigirse a SISSE para consultar las encuestas sincronizadas
            </Tab>
          </Tabs>
        </Card.Body>
      </Card>

		<div className="position-fixed bottom-1 end-1 z-index-2">
			<Toast show={mostrarToast} onClose={toggleMostrarToast}>
				<Toast.Header>
					<i class="material-icons me-2"></i>
					<span class="me-auto text-gradient text-info font-weight-bold">Resultado sincronización</span>
					<i class="fas fa-times text-md ms-3 cursor-pointer" data-bs-dismiss="toast" aria-label="Close" aria-hidden="true"></i>
				</Toast.Header>
				<Toast.Body>{toastBody}</Toast.Body>
			</Toast>
		</div>
    </>
  );
}