import React, { useState, useEffect } from "react";
import { Col, Row, Button } from "react-bootstrap";

import { useGeolocated } from "react-geolocated";

const GeoLocalizacion = (props) => {

	/*const [valoresGeo, setGeo] = useState({
		valorLatitudLongitud: [],
		isGeolocationEnabled: "",
		isGeolocationAvailable: ""
	});*/

	const {coords, isGeolocationAvailable, isGeolocationEnabled} =
		useGeolocated({
			positionOptions: {
				enableHighAccuracy: false,
			},
			userDecisionTimeout: 5000,
		});

	useEffect(() => {
		console.log(coords);		
		if (coords) {
			props.setLatitudLongitud([coords.latitude, coords.longitude]);
			props.setGeoLocalizar(false);
		}
	});

	console.log(coords);

	if (isGeolocationEnabled === false) {
		return <div>Geolocalización no disponible</div>;
	}

	if (isGeolocationAvailable === false) {
		return <div>Su navegador no soporta Geolocalización</div>;
	}
};

export default GeoLocalizacion;
