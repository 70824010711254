import React, { Component } from "react";
import {useLocation, NavLink} from "react-router-dom";
import { Navbar, Container, Row, Col} from "react-bootstrap";

import routes from "routes.js";

function Header({isLogged}) {

  const location = useLocation();
  const mobileSidebarToggle = (e) => {
    e.preventDefault();

    if (!document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.add("g-sidenav-pinned");
    } else {
      document.body.classList.remove("g-sidenav-pinned");
    }
  };

  const getBrandText = () => {
    for (let i = 0; i < routes.length; i++) {
      if (location.pathname.indexOf(routes[i].layout + routes[i].path) !== -1) {
        return routes[i].name;
      }
    }
    return "Valoracion Diabetes";
  };
  /*
  <a href="#" onClick={mobileSidebarToggle} class="p-0" id="iconNavbarSidenav">
  <div class="sidenav-toggler-inner">
    <i class="sidenav-toggler-line"></i>
    <i class="sidenav-toggler-line"></i>
    <i class="sidenav-toggler-line"></i>
  </div>
  </a>*/
  return (
    <>
       {isLogged ?(
      <Navbar className="mt-2 mb-2 shadow-none border-radius-xl">
        <div className="container-fluid d-xl-none ">
          <a className="navbar-brand" href="#"></a>          
          <a href="#" onClick={mobileSidebarToggle} className="p-0" id="iconNavbarSidenav">
            <div className="sidenav-toggler-inner">
              <i className="sidenav-toggler-line"></i>
              <i className="sidenav-toggler-line"></i>
              <i className="sidenav-toggler-line"></i>
            </div>
          </a>          
        </div>
      </Navbar>
      ) : (null)}
    </>
  );
}

export default Header;