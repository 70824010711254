import { convert_js_image_to_luma, decode_barcode } from "rxing/rxing_wasm";

const RxingWasmScanner = (img, width, height) => {
    /*let context = canvas.getContext('2d');
    let height = canvas.height;
    let width = canvas.width;
    let imageData = context.getImageData(0, 0, width, height);

    let data = imageData.data;*/
    let luma8Data = convert_js_image_to_luma(img.data);
    let parsedBarcode = decode_barcode(luma8Data, width, height);
    
    return parsedBarcode.text();
};

export default RxingWasmScanner;
