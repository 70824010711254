import Dashboard from "views/Dashboard.js";
import UserProfile from "views/UserProfile.js";
import EncuestasList from "views/EncuestasList.js";
import encuestas from "views/encuestas";
import Upgrade from "components/Upgrade/Upgrade";


const dashboardRoutes = [
  {
    itemId: 0,
    path: "/dashboard",
    name: "Inicio",
    icon: "fas fa-home",
    component: Dashboard,
    layout: "/admin",
    show: true
  },
  {
    itemId: 1,
    path: "/nueva-encuesta",
    name: "Nueva encuesta",
    icon: "fas fa-plus",
    component: encuestas,
    layout: "/admin",
    show: true
  },
  {
    itemId: 2,
    path: "/encuestaslist",
    name: "Listado encuestas",
    icon: "fas fa-list",
    component: EncuestasList,
    layout: "/admin",
    show: true
  },
  
  {
    itemId: 3,
    path: "/user",
    name: "Perfil Usuario",
    icon: "fas fa-user",
    component: UserProfile,
    layout: "/admin",
    show: false
  },  
   /*
 

 
 {
    itemId: 3,
    path: "/efectores",
    name: "Efectores",
    icon: "nc-icon nc-alien-33",
    component: selectCasa,
    layout: "/admin",
    show: true
  },
  {
    itemId: 9,
    path: "/encuestasLisnotsincronizadas",
    name: "Listado de Encuestas no Sincronizadas",
    icon: "nc-icon nc-notes",
    component: EncuestasListNoSincronizadas,
    layout: "/admin",
    show: true
  },
 {
    itemId: 8,
    path: "/geolocat",
    name: "Loclizacion",
    icon: "nc-icon nc-notes",
    component: GeoLocalizacion,
    layout: "/admin",
    show: true
  },
{
    id: 5,
    path: "/typography",
    name: "Typography",
    icon: "nc-icon nc-paper-2",
    component: Typography,
    layout: "/admin",
    show: true
  },
  {
    id: 6,
    path: "/icons",
    name: "Icons",
    icon: "nc-icon nc-atom",
    component: Icons,
    layout: "/admin",
    show: true
  },
 */
  
];

export default dashboardRoutes;