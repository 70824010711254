//import * as wasm from "rxing/rxing_wasm_bg.wasm";
import { 
  __wbg_set_wasm, 
  __wbindgen_string_new, 
  __wbindgen_object_drop_ref,
  __wbg_new_f841cc6f2098f4b5,
  __wbg_set_388c4c6422704173,
  __wbg_getTime_7c59072d1651a3cf,
  __wbg_new0_25059e40b1c02766,
  __wbindgen_throw
} from "rxing/rxing_wasm_bg";

const importObject = {
	"./rxing_wasm_bg.js": {
		"__wbindgen_string_new": __wbindgen_string_new,
		"__wbindgen_object_drop_ref": __wbindgen_object_drop_ref,
		"__wbg_new_f841cc6f2098f4b5": __wbg_new_f841cc6f2098f4b5,
		"__wbg_set_388c4c6422704173": __wbg_set_388c4c6422704173,
		"__wbg_getTime_7c59072d1651a3cf": __wbg_getTime_7c59072d1651a3cf,
		"__wbg_new0_25059e40b1c02766": __wbg_new0_25059e40b1c02766,
		"__wbindgen_throw": __wbindgen_throw
	}
};

WebAssembly.compileStreaming(fetch("/rxing_wasm_bg.wasm"))
    .then(module => {return WebAssembly.instantiate(module, importObject);})
    .then(instance => __wbg_set_wasm(instance.exports));

export * from "./rxing_wasm_bg.js";