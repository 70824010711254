import React, {useEffect} from "react";
import { useLocation, Route, Routes} from "react-router-dom";
import { Container } from "react-bootstrap";
import AdminNavbar from "components/Navbars/AdminNavbar";
import Sidebar from "components/Sidebar/Sidebar";
import dashboardRoutes from "routes.js";
import sidebarImage from "assets/img/sidebar-3.jpg";
import CheckAuth from "components/checkaut/CheckAuth";


function Admin() {
  const [image, setImage] = React.useState(sidebarImage);
  const [color, setColor] = React.useState("black");
  const [hasImage, setHasImage] = React.useState(true);
  const [isLogged, setIsLogged] = React.useState(null);
  const location = useLocation();
  const mainPanel = React.useRef(null);
  useEffect(() => {
    if (localStorage.getItem("token")){
      setIsLogged(true)
    }else {
      setIsLogged(false)
    }
  }, [isLogged]);
   
  const getRoutes = (dashboardRoutes) => {
    return dashboardRoutes.map((prop, id) => {
   
      if (prop.layout === "/admin"){
       
        return (
         <Route key={prop.itemId} element={<CheckAuth isLogged={isLogged} />} >
            <Route 
              path={prop.path}
              element={<prop.component  />}
            />  
         </Route>
        );
      } else return(null)
    });
  };
  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainPanel.current.scrollTop = 0;
    if (
      window.innerWidth < 993 &&
      document.documentElement.className.indexOf("nav-open") !== -1
    ) {
      document.documentElement.classList.toggle("nav-open");
      var element = document.getElementById("bodyClick");
      element.parentNode.removeChild(element);
    }
  }, [location]);
  return (
    <>

        <Sidebar color={color} image={hasImage ? image : ""} routes={dashboardRoutes}  isLogged={isLogged} setIsLogged={setIsLogged} />
        <main className="main-content border-radius-lg ps ps--active-y" ref={mainPanel}>
        <AdminNavbar isLogged={isLogged} setIsLogged={setIsLogged} />  
          <Container>
            <Routes> 
            {getRoutes(dashboardRoutes)}
            </Routes>
          </Container>
        </main>

      
    </>
  );
}

export default Admin;
