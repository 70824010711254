import React from "react";

import {
  Button,
  Card,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import { Link } from "react-router-dom";

function Dashboard() {
  return (
    <>
   
      <Container style={{marginTop: '20px', marginLeft:'10px', margin:'20px'}} fluid>

      
      <Row style={{width:"95%"}}>
        <Col className="col-sm-3 col-12 pt-1">
        <Link type={Button}  to="/admin/nueva-encuesta"> 
          <Card bg="info" border="primary">
            <Card.Header>
              <div className="btn btn-info text-center border-radius-xl">
                    <i className="fas fa-plus"></i>
              </div>
              <div className="text-end pt-1">
              <p className="text-sm mb-0 text-capitalize"></p>
              <h4 className="mb-0">Nueva Encuesta</h4>
              </div>
            </Card.Header>
            <Card.Footer className="p-3">            
            </Card.Footer>
          </Card>
        </Link>  
        </Col>
        <Col className="col-sm-3 col-12">
          <Link type={Button} to="/admin/encuestaslist"> 
          <Card bg="primary border-dark">
            <Card.Header>
              <div className="btn btn-primary text-center border-radius-xl">
                    <i className="fas fa-clock"></i>
              </div>
              <div className="text-end pt-1">
              <p className="text-sm mb-0 text-capitalize"></p>
              <h4 className="mb-0">Listado Encuestas</h4>
              </div>
            </Card.Header>
            <Card.Footer className="p-3">            
            </Card.Footer>
          </Card>
        </Link>  
        </Col>
        <Col className="col-sm-3 col-12">
          <Link type={Button} to="/admin/user"> 
          <Card bg="dark border-dark">
            <Card.Header>
              <div className="btn btn-dark text-center border-radius-xl">
                    <i className="fas fa-user"></i>
              </div>
              <div className="text-end pt-1">
              <p className="text-sm mb-0 text-capitalize"></p>
              <h4 className="mb-0">Mi Perfil</h4>
              </div>
            </Card.Header>
            <Card.Footer className="p-3">            
            </Card.Footer>
          </Card>
        </Link>  
        </Col>

</Row>
       
      </Container>
    </>
  );
}

export default Dashboard;
