import axios from "axios";
import React, { useEffect, useState } from "react";
import "react-form-wizard-component/dist/style.css";

// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Form,
  Navbar,
  Nav,
  Container,
  Row,
  Col
} from "react-bootstrap";

function Upgrade() {

  const[email, setEmail]= useState("");   
  const[username, setUsername]= useState("");
  const[password, setPassword]= useState("");   
  const[passwordNew, setPasswordNew]= useState("");   
  const[confirmPasswordNew, setConfirmPasswordNew]= useState("");   
  const[loading, setLoading]=useState(false); 
  const[errores, setErrores] = useState();
/*
 useEffect (() => {
    axios
      .get("",{
        headers:{
          Authorization:"Bearer" + localStorage.getItem("token")
        },          
      })
      .then((response) =>
     {
        console.log(response.data);
        setEmail(response.data.email);
        setUsername(response.data.username)
      })
      .catch((error) => {
        console.log(error.response.data);
      })
 })
 

  const handlesubmit = (e) => 
   {
     e.preventDefault();
     setLoading(true);
     setErrores("");
 
     const data = {realm, username, email, password};
     console.log(data);
   axios 
       .get("http://[::1]:3001/users/login", 28)
       .then((response) => {
        setUsername= (response.username);
       
       })
       .catch((error) => {
         console.log("no carga");
         if (error.response === 422){
           setErrores(error.response.data.errores);
         }
       })
       .finally(() => {
         setLoading(false);
       });
     };
*/

return (
    <>
      <Container fluid>
        <Row>
           <Col md="8">
            <Card>         
              <Card.Header>
                <Card.Title as="h4">Editar Perfil</Card.Title>
              </Card.Header>
              <Card.Body>
                <Form onSubmit={console.log("Los datos se actualizaron correctamente")}>
                  <Row>
                    <Col className="pr-1" md="11">
                      <Form.Group>
                        <label className="form-label">Usuario</label>
                        <Form.Control   
                          value={username}
                          onChange={(e) => setUsername(e.target.value)}
                          type="text"
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pr-1" md="5">
                      <Form.Group>
                        <label className="form-label">Contraseña actual</label>
                        <Form.Control
                          value={password}
                          type="pasword"
                          onChange={(e) => setPassword(e.target.value)}
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col  className="pl-1" md="5">
                      <Form.Group>
                        <label className="form-label">Nueva contraseña</label>
                        <Form.Control
                          type="number"
                          value= {passwordNew}
                          onChange={(e) =>setPasswordNew(e.target.value)}                          
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Button
                    className="btn-fill pull-right"
                    type="submit"
                    variant="info"
                  >
                    Actualizar Datos
                  </Button>
                  <div className="clearfix"></div>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Upgrade;