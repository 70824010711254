import { useEffect, useState } from "react";


const InstallPWA = () => {
    
  const [isReadyForInstall, setIsReadyForInstall] = useState(false);

  useEffect(() => {    
    window.addEventListener("beforeinstallprompt", (event) => {
      console.log("Evento beforeinstallprompt");
      // Prevent the mini-infobar from appearing on mobile.
      event.preventDefault();
      // Stash the event so it can be triggered later.
      window.deferredPrompt = event;
      // Remove the 'hidden' class from the install button container.
      setIsReadyForInstall(true);
    });
  }, []);

  const [isReadyForUnInstall, setIsReadyForUnInstall] = useState(false);

  useEffect(() => {    
    window.addEventListener('appinstalled', () => {
        console.log("App instalada");
        // Limpiar el defferedPrompt para que pueda ser eliminado por el recolector de basura
        deferredPrompt = null;
        setIsReadyForInstall(false);
        setIsReadyForUnInstall(true);
      });
  }, []);

  async function onClickInstall() {
    const promptEvent = window.deferredPrompt;
    if (!promptEvent) {
      // The deferred prompt isn't available.
      console.log("oops, no prompt event guardado en window");
      return;
    }
    // Show the install prompt.
    promptEvent.prompt();
    // Log the result
    // const result = await promptEvent.userChoice;
    // Reset the deferred prompt variable, since
    // prompt() can only be called once.
    window.deferredPrompt = null;
    // Ocultamos el instalar, mostramos el desinstalar
    setIsReadyForInstall(false);
    setIsReadyForUnInstall(true);
  }
/*
  async function onClickUnInstall() {
    window.deferredPrompt = null;
    // Mostramos el boton instalar
    setIsReadyForUnInstall(false);
    setIsReadyForInstall(true);
  }
*/
  if (isReadyForInstall && window.deferredPrompt) {
   return (
        <button
            className="btn btn-sm bg-gradient-primary w-100"
            id="setup_button"
            aria-label="Instalar app"
            title="Instalar app"
            onClick={onClickInstall}
            >
            Instalar App
        </button>
    );
  }
  /*
  if (isReadyForUnInstall && !window.deferredPrompt) {
    return (
        <button
            className="btn btn-outline-secondary w-100"
            id="setup_button"
            aria-label="Desinstalar app"
            title="Desinstalar app"
            onClick={onClickUnInstall}
            >
            Desinstalar App
        </button>  
     );
   }*/
};

export default InstallPWA;