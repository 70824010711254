import React, { useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import Modal from 'react-bootstrap/Modal';
import RadioButtonGroup from "../inputs/radioButton";
import "react-form-wizard-component/dist/style.css";


export function ModalPresion(props){   
    return (
        <div>
            <Modal {...props} aria-labelledby="contained-modal-title-vcenter">
                <Modal.Header className="bg-dark" closeButton>
                    
                        <p className="text-white">Info para toma de presion</p>
                    
                </Modal.Header>
                <Modal.Body className="grid-example">
                <div className="card-image text-center">
                            <img alt="..." width={"80%"} src={require("assets/img/tension.png")}></img>
                        </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={props.onHide}>Cerrar</Button>
                </Modal.Footer>
            </Modal>
        </div>
       
    );
}