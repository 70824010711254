import React, {useEffect} from "react";
import ReactDOM from "react-dom/client";

import { HashRouter, Route, Routes, Redirect, Navigate } from "react-router-dom";

import "@fortawesome/fontawesome-free/css/all.min.css";

import "./assets/material/css/material-dashboard.min.css";
import "./assets/material/css/nucleo-icons.css";
import "./assets/material/css/nucleo-svg.css";
import "./assets/css/encuesta.css";

import Login from "views/Login";
import Admin from "layouts/Admin";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
	<HashRouter>
		<Routes>
            <Route path="" element={<Login />} />

            <Route path="/" element={<Login />} />
      
      	    <Route path="/admin/*" element={<Admin />} />
      
      	    <Route path="*" element={<Navigate replace to="/" />} />

		</Routes>
  	</HashRouter>
);
