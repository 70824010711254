import React, { useEffect } from 'react'
import Select from 'react-select'
import { Form } from "react-bootstrap";

export default function SelectEfector (props) {
	
	const Efectores = JSON.parse(localStorage.getItem("user")).efectores;     
	let efectorSeleccionado = Efectores.find(function(item) {
		return item.id_efector === props.valorEfector;
	});

	let defaultEfector = {value: "", label: "Seleccione"};
	if (efectorSeleccionado) {
		defaultEfector = {value: efectorSeleccionado.id_efector, label: efectorSeleccionado.nombre};
	}

	useEffect(() => {
		if (Efectores.length == 1) {
            let localidades = Efectores[0].localidades.map(localidad =>({ label:localidad.nombre, value:localidad.id_localidad}));
            props.setValorEfector(Efectores[0].id_efector);
			props.setValorNombreEfector(Efectores[0].nombre);
            props.setLocalidadesOptions(localidades);            
		}
	}, []);

   const handleSelectChange = (event) => {     

        let efectorSeleccionado = Efectores.find(function(item) {
            return item.id_efector === event.value;
     	});
     
   	    let localidades = efectorSeleccionado.localidades.map(localidad =>({ label:localidad.nombre, value:localidad.id_localidad}));

    	props.setValorEfector(event.value);
		props.setValorNombreEfector(efectorSeleccionado.nombre);
    	props.setLocalidadesOptions(localidades);
		props.handleLocalidadChange({value: "", label: "Seleccione"});
   }

	return (
		<div padding ="1r">
            <label className="form-label">Efector</label>
				{
					(Efectores.length > 1)?
					<>
						<Select 							
                    	value={defaultEfector}
                    	options={Efectores.map(sucursales =>({ label:sucursales.nombre, value:sucursales.id_efector}))}
                    	onChange={handleSelectChange}
							className={`${props.formValidationErrors.idEfector ? "is-invalid" : ""}`}
						/>
						<Form.Control.Feedback type="invalid">{props.formValidationErrors.idEfector}</Form.Control.Feedback>
					</>
                :
						<p>
							{Efectores[0].nombre}
						</p>                
				}
		</div>
	);
 }
 