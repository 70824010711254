import React, { useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import Modal from 'react-bootstrap/Modal';
import RadioButtonGroup from "../inputs/radioButton";
import "react-form-wizard-component/dist/style.css";


export function ModalList(props){   
    const [valorCandidato, setValorCandidato ] = useState(0);
    const preguntaCandidato = "Seleccione el candidato correcto";
    let opcionesCandidato = [];
   
    if(props.candidatos !== "[]"){
        const cand = JSON.parse(props.candidatos);
        //console.log(cand);
        opcionesCandidato = cand.map((value) => {
            let candidato=  [
                            value.apellido+" "+value.nombre+"(DNI: "+value.documento+")" + " - F. Nac: " + value.fecha_nacimiento,
                            value.id
                          ];
            return candidato;
        });
        opcionesCandidato.push(["Registrar como nuevo paciente", "0"])
    }
    const seleccionarPaciente = () => {
      console.log(valorCandidato);
      console.log(Number(valorCandidato));
      let arrayDatos = JSON.parse(localStorage.getItem("datos")).encuestas;
      const encuesta = arrayDatos.find(object => {
        return object.id === props.encuestaindex;
      });
      encuesta.estado = "PENDIENTE_CANDIDATO_SELECCIONADO";       
      if(valorCandidato === "0"){
        encuesta.agregarPersona = true;
        encuesta.personaElegida = 0;
      }else{
        encuesta.agregarPersona = false;
        encuesta.personaElegida = valorCandidato;
      }
      arrayDatos.forEach(element => {
        const itemIndex = encuesta.id === element.id;
        if(itemIndex > -1) {
          arrayDatos[itemIndex] = parseInt(element) ;
        }          
      }); 
      localStorage.setItem("datos", '{"encuestas": ' + JSON.stringify(arrayDatos) + '}');
      
      props.onHide();
    }  
    const [agregarPersona, setAgregarPersona] = useState(false); 
    const [agregar, setAgregar] = useState("hidden"); 
    const [seleccionar, setSeleccionar] = useState("");  

    const showHide = () => {
      console.log(agregarPersona);
      if(agregarPersona){
        setSeleccionar('');
        setAgregar('hidden');          
        setAgregarPersona(false);
      }else{
        setSeleccionar('hidden');
        setAgregar('');
        setAgregarPersona(true);
      }
    }

    return (
        <div>
            <Modal {...props} aria-labelledby="contained-modal-title-vcenter">
                <Modal.Header className="bg-dark" closeButton>
                    
                        <p className="text-white">Seleccione el candidato</p>
                    
                </Modal.Header>
                <Modal.Body className="grid-example">
                    <Container>
                      <Row className={ seleccionar }>
                          <Col xs={12} md={12}>                      
                              <RadioButtonGroup className="Radiobuton"
                                nombre={"candidatoelegido"} 
                                pregunta={preguntaCandidato} 
                                inputs={opcionesCandidato}  
                                valorParametro={valorCandidato} 
                                setValor={setValorCandidato}
                                formValidationErrors={{}}
                                vertical={true}
                              />
                          </Col>
                      </Row>
                      <Row className={ agregar }>                       
                                             
                      </Row>
                    </Container>
                  
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={props.onHide}>Cerrar</Button>
                    <Button className="btn btn-info" onClick={seleccionarPaciente}>Guardar</Button>                    
                </Modal.Footer>
            </Modal>
        </div>
       
    );
}