import React, { Component } from "react";
import { useLocation, NavLink, useNavigate } from "react-router-dom";

import InstallPWA from "../pwa/InstallPWA";
const { version: appVersion } = require('../../../package.json');

function Sidebar({routes, isLogged, setIsLogged }) {

  const mobileSidebarToggle = (e) => {
    e.preventDefault();

    if (!document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.add("g-sidenav-pinned");
    } else {
      document.body.classList.remove("g-sidenav-pinned");
    }
  };

	const navigate = useNavigate("");
	const logout = () =>{
		localStorage.removeItem("token");
		setIsLogged(false);
		navigate("/");
	}

  	const location = useLocation();
  	const activeRoute = (routeName) => {
		return location.pathname.indexOf(routeName) > -1 ? "active" : "";
  	};

  	return (
    <>
      {isLogged ? (
      <aside className="sidenav navbar navbar-vertical navbar-expand-xs border-0 border-radius-xl my-3 fixed-start ms-3 bg-gradient-dark ps bg-white" id="sidenav-main">
        <div className="sidenav-header">
          <a href="#" onClick={mobileSidebarToggle} className="p-0" id="iconNavbarSidenav">
            <i className="fas fa-times p-3 cursor-pointer text-white opacity-5 position-absolute end-0 top-0 fa-2x d-xl-none" aria-hidden="true" id="iconSidenav"></i>
          </a>   
          <a className="navbar-brand m-0" href="#" target="_blank">
            <img src={require("assets/img/logosalud.png")} alt="..." />
            <span className="ms-1 font-weight-bold text-white">Diabetes</span>
            <span className="ms-1 text-white">{appVersion}</span>
          </a>
        </div>
        <hr className="horizontal light mt-0 mb-2" />

        <div className="collapse navbar-collapse w-auto ps ps--active-y" id="sidenav-collapse-main">
          <ul className="navbar-nav" onClick={mobileSidebarToggle}>
            {routes.map((prop, key) => {
                if (!prop.redirect)
                  return (
                    <li
                      className={
                        prop.upgrade
                          ? "active active-pro"
                          : activeRoute(prop.layout + prop.path)
                      }
                      key={key}
                    >
                      <NavLink
                        to={prop.layout + prop.path}
                        className="nav-link"
                        activeclassname="active"
                        show="true"
                      >
                        <i className={prop.icon} />
                        <p style={{paddingLeft: '5px'}}> {prop.name}</p>
                      </NavLink>
                    </li>
                  );
                return null;
            })}
          </ul>
        </div>

		<div className="sidenav-footer position-absolute w-100 bottom-0">
			<div className="mx-3">
                <a className="btn btn-sm bg-gradient-info" target="_blank" href="https://sisse.msalsgo.gob.ar/form/forms">
                    Ver todas sus encuestas en SISSE
                </a>
                <hr class="horizontal light mt-0 mb-2"></hr>
				<InstallPWA></InstallPWA>
				<a className="btn btn-sm btn-outline-primary w-100"   
					onClick={logout} 
					type="button">Cerrar sesion</a>
			</div>
		</div>
   
	</aside>
  
      ) : (null)}
     
    </>

   
 );
}

export default Sidebar;
