import { useNavigate, Link } from "react-router-dom";
import  axios  from "axios";
import {React, useState} from "react";

import {
  Button,
  Card,
  Form,
  Container,
  Row,
  Col
} from "react-bootstrap";


function Login(){
  const[username, setUsername]= useState("");   
  
  const[password, setPassword]= useState("");   
  const[loading, setLoading]=useState(false); 
  const[errores, setErrores] = useState("");
  
  
  const history = useNavigate();
  
  const handlesubmit = (e) => 
   {
     e.preventDefault();
     setLoading(true);     
 
    const data = {username, password};
  
   axios 
		.post(process.env.REACT_APP_API_URL+'login', data)
		.then((response) => {
			if(typeof(response.data.success) !== "undefined" && response.data.success !== false) {
				localStorage.setItem("token", response.data.token);
				localStorage.setItem("user", JSON.stringify(response.data.user));
				if (!localStorage.getItem("datos")) {
					localStorage.setItem("datos", JSON.stringify({"encuestas":[]}));
				}
				history("/admin/dashboard");
        	} else {
				setErrores(response.data.msg);				
        	}        
		})
		.catch((error) => {         
            setErrores("Ocurrio un error, por favor intente mas tarde");
		})
		.finally(() => {
         setLoading(false);
		});
	};

  	return (
   	<>
    	<Row className=" mt-5">
        <Col className="col-lg-4 col-md-8 col-12 mx-auto">
            <Card className="z-index-0 fadeIn3 fadeInBottom">
                <Card.Header className=" p-0 position-relative mt-n4 mx-3 z-index-2">
                    <div className="shadow-primary border-radius-lg py-2 pe-1">                        
                        <div className="card-image text-center">
                            <img alt="..." width={"80%"} src={require("assets/img/min.png")}></img>
                        </div>
                        <h4 className="font-weight-bolder text-center mt-2 mb-0">Login</h4>
                    </div>
                </Card.Header>
                
                        <Form className="" onSubmit={handlesubmit} >
                          <Card.Body>
                            <Row>
                                <Col  className="pr-1">
                                    <Form.Group>
                                       <label className="text-center" md="12">Usuario</label>
                                        <Form.Control
                                        className="ps-2 square border"
                                        type="text"
                                        value={username}
                                        onChange={(e) => setUsername(e.target.value)}
                                        >
                                        </Form.Control>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="pr-2 " md="12">
                                <Form.Group>
                                    <label>Contraseña</label>
                                    <Form.Control
                                    className="ps-2 square border"
                                    type="password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    >
                                    </Form.Control>
                                </Form.Group>
                                </Col>
                            </Row>
                            <div className="clearfix"></div>
                        </Card.Body>
                        <Card.Footer>
                            <Button  
                                className="btn-fill pull-right w-100" 
                                disabled={loading}
                                type="submit"
                                variant="info"
                            >
                                {loading ? (<div className="spinner-grow text-light" role="status">
                                            <span className="visually-hidden">Cargando...</span>
                                            </div>) :("Ingresar")}
                            
                            </Button>
                            {errores != "" &&
                                <p class="lead text-center text-danger">{errores}</p>
                            }                            
                            </Card.Footer>
                        </Form>
                
            </Card>
        </Col>
    	</Row>
    	</>
  	);
}

export default Login;


