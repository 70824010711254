import React from "react";
// react-bootstrap components
import {
  Card,
  Container,
  Row,
  Col,
  Button
} from "react-bootstrap";
function User() { 
  const loggedUser = JSON.parse(localStorage.getItem("user"));
return (
        <>
        <Container fluid>
            <Row>
            <Col md="8">
                <Card>         
                <Card.Header>
                    <Card.Title as="h4">Perfil de Usuario</Card.Title>
                </Card.Header>
                <Card.Body>
                <ul className="list-group">
                    <li className="list-group-item border-0 ps-0 text-sm">
                    <strong className="text-dark">Nombre:</strong>{loggedUser.nombre}</li>
                    <li className="list-group-item border-0 ps-0 text-sm">
                    <strong className="text-dark">Apellido:</strong>{loggedUser.apellido}</li>
                    </ul>
                    <hr className="horizontal gray-light my-4">
                    </hr> 
                </Card.Body>
                </Card>
            </Col>
            </Row>
        </Container>
        </>
    );
} export default User;
