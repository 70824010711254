import React, { useState } from "react";
import { Form } from "react-bootstrap";
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import "./radioButton.scss";

function RadioButtonGroup({nombre, inputs, valorParametro, setValor, vertical, formValidationErrors, disabled}) {
 
  return (
         <>
            <ButtonGroup 
                    className={`col-12 ${
                                    formValidationErrors[nombre] ? "is-invalid" : ""
                                }`} 
                    aria-label="" 
                    vertical={vertical}>
               {
                  inputs.map(([text, value], i) => (                     
                     <React.Fragment key={i}>
                        <input 
                           type="radio" 
                           className="btn-check" 
                           name={`${nombre}`}
                           key={`${value}`}
                           id={`${value}`} 
                           value={`${value}`}
                           checked={ (valorParametro == value)  ? true : false}
                           onChange={(e) => {setValor(e.target.value)}}
                           autoComplete="off" 
                           disabled={disabled}
                           />
                        <label key={`label-${value}`} className="btn btn-outline-secondary" htmlFor={`${value}`}>{text}</label>                     
                     </React.Fragment>
                  ))        
               }
            </ButtonGroup>
            <Form.Control.Feedback type="invalid">{formValidationErrors[nombre]}</Form.Control.Feedback>
         </>
  );
}
export default RadioButtonGroup;