import { useState } from "react";
import { Button, Card, Col, Container, Form, Row, CardBody } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import RxingWasmScanner from "scanners/rxingwasm-scanner";
import InputMask from 'react-input-mask';
import * as yup from "yup";
import FormWizard from "react-form-wizard-component";

import "react-form-wizard-component/dist/style.css";

import GeoLocalizacion from "components/GeoLocalizacion/GeoLocalizacion";
import { PresionInput }from "components/inputMask/presionInput";
import { ModalPresion } from "components/Modal/ModalPresion";
import RadioButtonGroup from "../components/inputs/radioButton";
import SelectEfector from "components/Efector/SelectEfector";
import Select from 'react-select'

//export const WizardContext = createContext();

let valorAcreditaIdentidad = false;
let valorDocumentoQr = "";
    
export default function MyForm() {
   const [modalShowP, setModalShowP] = useState(false);

	const router = useNavigate();   

	const [valorGuardar, setValorGuardar] = useState("hidden"); 
   const [formValidationErrors, setCurrentErrors] = useState([]);
 
	// Para deshabilitar los campos de Nombre, Apellido, Dni, Sexo y Fecha Nacimiento
	const [isInputEnabled, setIsInputEnabled] = useState(true);	

	// Datos Personales   
	const [file, setFile] = useState("");  
	const [valorNombre, setValorNombre] = useState("");   
	const [valorApellido, setValorApellido] = useState(""); 
	const [valorDni, setValorDni] = useState("");
	const opcionesSexo = [["Masculino", "M"], ["Femenino", "F"], ["Indefinido", "I"]]; 
	const [valorSexo, setValorSexo] = useState("");    
   const [valorFechaNac, setValorFechaNac] = useState("");	
   const [valorEdad, setValorEdad] = useState("");    
	
	//Datos Domicilio
	const [valorCalle, setValorCalle] = useState(""); 
	const [valorNumero, setValorNumero] = useState(""); 
	const [valorManzana, setValorManzana] = useState("");
	const [valorLote, setValorLote] = useState("");
	const [valorSector, setValorSector] = useState("");
	const [valorGrupo, setValorGrupo] = useState("");
	const [valorTorre, setValorTorre] = useState("");
   const [valorEfector, setValorEfector] = useState("");
   const [valorNombreEfector, setValorNombreEfector] = useState("");
   const [valorLocalidad, setValorLocalidad] = useState("");
   const [valorLocalidadesOptions, setValorLocalidadesOptions] = useState({ value: "", label: "Seleccione" });
   const [localidadesOptions, setLocalidadesOptions] = useState([]);

   //Datos presion arterial
   const [valorPresion1, setValorPresion1] = useState(""); 
   const [valorPresion2, setValorPresion2] = useState(""); 

	const [valorPiso, setValorPiso] = useState("");
	const [valorDepto, setValorDetpo] = useState("");
	const [valorCalle_1, setValorCalle_1] = useState("");
	const [valorCalle_2, setValorCalle_2] = useState("");
	const [valorBarrio, setValorBarrio] = useState("");
   const [valorBarrioOption, setValorBarrioOption] = useState("");
   const [barriosOptions, setBarriosOptions] = useState([]);   
   
   const [valorLatitudLongitud, setLatitudLongitud] = useState([]);
   //const [valorIsGeolocationAvailable, setIsGeolocationAvailable] = useState("");
   //const [valorIsGeolocationEnabled, setIsGeolocationEnabled] = useState("");
   const [geoLocalizar, setGeoLocalizar] = useState(false);

	const opcionesEdad = [["Menos 45 años", "0"], ["45 a 55 años ", "2"], ["56 a 64 años", "3"], ["Mayor de 64 años", "4"]];

	const preguntaFamiliar = "¿Ha habido un diagnostico de DIABETES en, por lo menos, un miembro familiar?";
	const opcionesFamiliar = [["NO", "0"], ["SI:abuelos,tios o primos hermanos", "3"], ["SI:Padre, hijos o hermanos", "5"]];
	const [valorFamiliar, setValorFamiliar] = useState("");

	const preguntaPerimetro = "¿Que perimetro de cintura tiene medido a nivel ombligo?";
	const opcionesPerimetro = [["Menos de 90 cm (mujeres) - menos de 94 cm (hombres) ", "0"], ["igual o mayor a 90 cm (mujeres) - igual o mayor a 94 cm (hombres) ", "4"]];
	const [valorPerimetro, setValorPerimetro] = useState("");

	const preguntaActividad = "¿Realiza actividad fisica por lo menos 30 minutos diarios?";
	const opcionesActividad = [["SI ", "0"], ["NO", "2"]];
	const [valorActividad, setValorActividad] = useState("");

	const preguntaVerduras = "¿Con que frecuencia come frutas o verduras?";
	const opcionesVerduras = [["Todos los dias ", "0"], ["No todos los dias", "1"]];
	const [valorVerdura, setValorVerdura] = useState("");

	const preguntaMedicamentoHipertension = "¿Le han recetado medicamentos para la hipertension arterial?";
	const opcionesMedicamentoHipertension = [["NO ", "0"], ["SI", "2" ]];
	const [valorMedicamentoHipertension, setValorMedicamentoHipertension] = useState("");

	const preguntaGlucosa = "¿Le han encontrado alguna vez valores de glucosa alto?";
	const opcionesGlucosa = [["NO ", "0"], ["SI", "5"]];
	const [valorGlucosa, setValorGlucosa] = useState("");

	const preguntaMasa = "¿Cual es su indice de masa corporal?. Se calcula dividiendo dos veces la altura por su peso";
	const opcionesMasa = [["Menos de 25 ", "0"], ["Entre 25 y 30", "1" ], ["Mas de 30","2"]];
	const [valorMasa, setValorMasa] = useState("");

	const [total, setTotal] = useState(0);

	function addValue(sumaValor){
		let valorAdd = 0;
		for (let numeroadd of sumaValor){
			valorAdd+= parseInt(numeroadd);
		}
		return valorAdd      
	}

   const calcularTotal = () => {   
      console.log("calculando");
      const sumaValor = [valorEdad, valorFamiliar, valorPerimetro,  valorActividad,valorVerdura, valorMedicamentoHipertension, valorGlucosa, valorMasa];
      console.log(sumaValor);
      const sumaTotal = addValue(sumaValor);
      setTotal(sumaTotal);
      setValorGuardar("");
   }

	const calcularEdad = (fecha) => {	
      var vEdad = 0;	
		var dob = new Date(fecha);  
		//calculate month difference from current date in time  
		var month_diff = Date.now() - dob.getTime();  

		//convert the calculated difference in date format  
		var age_dt = new Date(month_diff);   

		//extract year from date      
		var year = age_dt.getUTCFullYear();  
		//now calculate the age of the user  
		let edad = Math.abs(year - 1970);
      console.log(edad);
		if (edad <= 45) {
			vEdad = 0;
		}
		if (edad > 45 && edad <= 55) {
			vEdad = 2;
		}
		if (edad > 55 && edad <= 64) {
			vEdad = 3;
      }
      if (edad > 64){
         vEdad = 4;
      }

      setValorEdad(vEdad);     
	}

   const handleComplete = () => {
		
		let userLocalStorage = JSON.parse(localStorage.getItem("user"));

      let arrayDatos = localStorage.getItem("datos");
      const estado = "PENDIENTE";
      const fecha = new Date().toLocaleString();   

      let obj = JSON.parse(arrayDatos);
      const datos = {
			"id": obj['encuestas'].length,
			"personaElegida": 0,
			"candidatos":{},
			"agregarPersona":false,
			"persona": {
					"nombre": valorNombre,
					"apellido": valorApellido,          
					"documento": valorDni,
					"fecha_nacimiento": valorFechaNac,
					"sexo": valorSexo,
					"acredita_identidad": valorAcreditaIdentidad,
					"documento_qr": valorDocumentoQr,
                    "foto": file,
					"domicilio": {
						"calle": valorCalle,
						"numero": valorNumero,
						"manzana": valorManzana,
						"lote": valorLote,
						"sector": valorSector,
						"grupo": valorGrupo,
						"torre": valorTorre,
						"piso": valorPiso,
						"depto": valorDepto,
						"entre_calle_1": valorCalle_1,
						"entre_calle_2": valorCalle_2,
						"barrio": valorBarrio,
						"id_localidad": valorLocalidad,
						"latitud": valorLatitudLongitud.length == 0 ? "" : valorLatitudLongitud[0].toString(),
						"longitud": valorLatitudLongitud.length == 0 ? "" : valorLatitudLongitud[1].toString(),
						"urbano_rural": ""
					}
			},
			"edad": valorEdad,
			"familia": valorFamiliar,
			"perimetro": valorPerimetro,
			"actividad": valorActividad,
			"verdura": valorVerdura,
			"medicamento_hipertension": valorMedicamentoHipertension,
			"glucosa": valorGlucosa,
			"masa": valorMasa,
			"fecha": fecha,
			"estado": estado,
			"id_efector": valorEfector.toString(),
            "nombre_efector": valorNombreEfector.toString(),
			"presion1": valorPresion1,
			"presion2": valorPresion2,
			"total": total.toString(),
			"user_id": typeof(userLocalStorage.id) === 'undefined' ? 0 : userLocalStorage.id,
            "user_nombre": typeof(userLocalStorage.nombre) === 'undefined' ? '' : userLocalStorage.nombre,
            "user_apellido": typeof(userLocalStorage.apellido) === 'undefined' ? '' : userLocalStorage.apellido
      };     
      console.log(datos);
      obj['encuestas'].push(datos);
      const jsonStr = JSON.stringify(obj);
      localStorage.setItem("datos",  jsonStr);
      router("/admin/encuestaslist");  
   }

   const backTemplate = (handlePrevious => {
      return (
        <Button className="base-button btn-fill wizard-footer-left w-40 d-lg-w-40 btn btn-primary mt-6" onClick={handlePrevious}>
            <i className="fas fa-arrow-left"></i> Atras
        </Button>
      );
   });

	const nextTemplate = (handleNext => {
		return (
			<Button className="base-button btn-fill wizard-footer-right w-40 d-m-w-40 btn btn-primary mt-6" onClick={handleNext}>
					Siguiente <i className="fas fa-arrow-right"></i>
			</Button>
		);
	});

   const finishTemplate = (handleNext => {
		return (
			<Button className="base-button btn-fill wizard-footer-right w-40 d-m-w-40 btn btn-success mt-6" onClick={handleComplete}>
					Guardar <i className="fas fa-save"></i>
			</Button>
		);
	});

	const handleTabChange = ({ prevIndex, nextIndex }) => {
		if (nextIndex == 10) {
			calcularTotal();
		}
	};

   const handleLocalidadChange = (event) => {
      
      if (valorEfector == "") return;

      if (event.value == "") {
        setValorLocalidadesOptions(event);
        setValorLocalidad(event.value);
        setValorBarrio("");
		  setBarriosOptions([]);        
        return;
      }

      const efectores = JSON.parse(localStorage.getItem("user")).efectores;

      const efector = efectores.find(function(item) {
         return item.id_efector === valorEfector;
     	});

      const localidad = efector.localidades.find(function(item) {
         return item.id_localidad === event.value;
     	});

      // esto para que el select mantenga el valor elegido
      setValorLocalidadesOptions(event);
      setValorLocalidad(event.value);

      const barrios = localidad.barrios.map(barrio =>({label: barrio.nombre, value: barrio.id_barrio}));      
      barrios.unshift({value: "2962", label: "SIN BARRIO"});
      barrios.unshift({value: "2961", label: "NO LISTADO EN SISSE"});

      setBarriosOptions(barrios);
      setValorBarrio("");
      setValorBarrioOption("");
   }
    
   const handleBarrioChange = (event) => {
      setValorBarrio(event.value);
      setValorBarrioOption(event);
   }

   let errores = [];

	const handleFileChange = async (event) => {

		if (event.target.files.length == 0) {
			valorAcreditaIdentidad = false;
			setIsInputEnabled(true);
			return;
		}

		if (event.target.files[0].type !== 'image/jpeg') {
			formValidationErrors.archivo = "Formato de archivo no permitido";         
         return;
		}
		
      const [ imageFile ] = event.target.files;
      const { type: mimeType } = imageFile;
    
      const fileReader = new FileReader();
      fileReader.readAsDataURL(imageFile);
      fileReader.onload = (fileReaderEvent) => {
    
         const imageAsBase64 = fileReaderEvent.target.result;
         //const image = document.createElement("img");
			const image = new Image();
         image.src = imageAsBase64;
			image.crossOrigin = 'Anonymous';
    
			image.onload = () => {
				const canvas = document.createElement('canvas');
				
				let imageResizeWidth = 1600
				if (image.width < 1600) {
					imageResizeWidth = image.width;
				}

				canvas.width = imageResizeWidth;
				canvas.height = canvas.height = ~~(image.height * (imageResizeWidth / image.width));
				
				const context = canvas.getContext('2d', { alpha: false });
				if (!context) {
					return;
				}
				context.drawImage(image, 0, 0, canvas.width, canvas.height);
		
				const imgData = context.getImageData(0, 0, canvas.width, canvas.height);
				
				const res = RxingWasmScanner(imgData, canvas.width, canvas.height);

				if (res == "") {
					formValidationErrors.archivo = "No se pudo procesar la informacion del archivo subido";
					return;
				}
				let scanArrayDatosPersonales = res.split("@");
            const objectLength = scanArrayDatosPersonales.length;
				if (!(objectLength == 8 || objectLength == 9)) {
					formValidationErrors.archivo = "No se pudo procesar la informacion del archivo subido";					
               return;
				}

            if (scanArrayDatosPersonales[8] === "M" || scanArrayDatosPersonales[8] === "F"){               
               setValorSexo(scanArrayDatosPersonales[3]);
               setValorDni(scanArrayDatosPersonales[4]);   
               setValorApellido(scanArrayDatosPersonales[1]);
				   setValorNombre(scanArrayDatosPersonales[2]);	            
            }else {
               if(scanArrayDatosPersonales[3]=='M' || scanArrayDatosPersonales[3]=="F"){
                  setValorSexo(scanArrayDatosPersonales[3]);
                  setValorDni(scanArrayDatosPersonales[4]);  
                  setValorApellido(scanArrayDatosPersonales[1]);
				      setValorNombre(scanArrayDatosPersonales[2]);                  
               }
            }
            
				valorAcreditaIdentidad = true;
				valorDocumentoQr = res;
				setFile(canvas.toDataURL('image/jpeg'));
				setIsInputEnabled(false);						
				
				let arrayFecha = scanArrayDatosPersonales[6].split("/");
            setValorFechaNac(arrayFecha[2] + "-" + arrayFecha[1] + "-" + arrayFecha[0]);
            calcularEdad(arrayFecha[2] + "-" + arrayFecha[1] + "-" + arrayFecha[0]);
            
			}
      };
	};
   console.log(formValidationErrors);
	const quitarFoto = async (event) => {
		setFile("");
		setIsInputEnabled(true);
		setValorApellido("");
		setValorNombre("");
		setValorSexo("");
		setValorDni("");
		setValorFechaNac("");
	};

   // seteamos el estado de fecha de nacimiento
   // y tambien el estado de la edad
   const setFechaNacimiento = (fecha) => {
		calcularEdad(fecha);
		setValorFechaNac(fecha);
   }

   // check validate tab
   const checkValidateTab = (nombrePropiedad, value, opciones)  => {
		
		let valoresOpciones = opciones.map(([text, value], i) => { return value; } );
		const opcionesPregunta = yup.object().shape({
			propiedad: yup.string().required("Campo requerido").oneOf(valoresOpciones, "Campo requerido"),			
		});

      let valido = true;
      try {
         opcionesPregunta.validateSync({
				propiedad: value,
         }, { abortEarly: false });
      } catch (err) {
         valido = false;
         err.inner.forEach(error => {               
               errores[nombrePropiedad] = error.message;
            });
      }

      return valido;
   };

   const datosDomicilio = yup.object().shape({
      barrio: yup.string().required("campo requerido"),
      calle: yup.string().required("campo requerido"),
      numero: yup.string().required("campo requerido"),
      idEfector: yup.string().required("campo requerido"),
      idLocalidad: yup.string().required("campo requerido"),
   });

   const validarPasoDatosDomicilio = () => {
      let valido = true;
      try {
         datosDomicilio.validateSync({
            barrio: valorBarrio,
            calle: valorCalle,
            numero: valorNumero,
            manzana: valorManzana,
            lote: valorLote,
            idEfector: valorEfector,
            idLocalidad: valorLocalidad
         }, { abortEarly: false });
      } catch (err) {
         valido = false;
         err.inner.forEach(error => {
            errores[error.path] = error.message;
         });
      }
      return valido;
   };
  
   const fechaActual = new Date();
   const menosCincoAnios = fechaActual.setFullYear(fechaActual.getFullYear() - 5);
   const personaDatosPersonales = yup.object().shape({
      nombre: yup.string().nullable().matches(/^[A-ZÁÉÍÓÚÑa-záéíóúñ\s]+$/, "Debe especificar solamente letras").required("Campo requerido"),
      apellido: yup.string().matches(/^[A-ZÁÉÍÓÚÑa-záéíóúñ\s]+$/, "Debe especificar solamente letras").required("Campo requerido"),
      dni: yup.number("Solo números").required("Campo requerido"),
      sexo: yup.string().required("Campo requerido").oneOf(['M', 'F', 'I'], "Campo requerido"),
      fechaNacimiento: yup.date().max(new Date(menosCincoAnios), "La fecha tiene un valor incorrecto").required()
   });

   const validarPasoDatosPersonales = () => {
   
      let valido = true;
      try {
         personaDatosPersonales.validateSync({
               nombre: valorNombre,
               apellido: valorApellido,
               dni: valorDni,
               sexo: valorSexo,
               fechaNacimiento: valorFechaNac,
         }, { abortEarly: false });
      } catch (err) {
         valido = false;
         err.inner.forEach(error => {               
               errores[error.path] = error.message;
            });
      }

      return valido;
   };
   
   const datosPresion = yup.object().shape({
      presion1: yup.string().required("campo requerido")
                  .matches(/[0-9][0-9][0-9]{1}\/[0-9][0-9][0-9]/, "Al parecer faltan algunos caracteres"),
      presion2: yup.string().required("campo requerido")
                  .matches(/[0-9][0-9][0-9]{1}\/[0-9][0-9][0-9]/, "Al parecer faltan algunos caracteres"),
   });

   const validarPasoDatosPresion = () => {
      let valido = true;      
      try {
         datosPresion.validateSync({
            presion1: valorPresion1,
            presion2: valorPresion2,
         }, { abortEarly: false });
      } catch (err) {
         valido = false;
         err.inner.forEach(error => {
            errores[error.path] = error.message;
         });
      }

      return valido;
   };
  
	// error messages
	const errorMessages = () => {
		setCurrentErrors(errores);
   };

   //formValidationErrors = errores;

   return (
    <>
      
      <FormWizard
         stepSize="xs"          
         backButtonTemplate={backTemplate}
         nextButtonTemplate={nextTemplate}
         finishButtonTemplate={finishTemplate}
         //onComplete={handleComplete}
         onTabChange={handleTabChange}
         title="8 preguntas para saber cual es tu riesgo de enfermar de DIABETES Tipo 2 en los proximos 10 años"
      >
      <FormWizard.TabContent
         title="Datos personales"
      >
         <Form.Label className="">Foto del documento</Form.Label>
         <div className="input-group mb-3">
            
            <input id="file" type="file" className={`form-control ${formValidationErrors.archivo ? "is-invalid" : ""}`} onChange={handleFileChange} />
            {file && <button className="btn btn-outline-primary" onClick={quitarFoto}>Quitar foto</button>}
            <Form.Control.Feedback type="invalid">{formValidationErrors.archivo}</Form.Control.Feedback>
         </div>
         
            
         <div className="mb-3">
            <Form.Label>Nombre</Form.Label>
            <Form.Control
               id="persona-nombre"
               className={`border ps-2 ${
                  formValidationErrors.nombre ? "is-invalid" : ""
               }`}
               placeholder="Nombre"
               type="text"
               value={valorNombre}              
               onChange={(e) => setValorNombre(e.target.value.toUpperCase())}
               isInvalid={!!formValidationErrors.nombre}
               disabled={!isInputEnabled}
            />
            <Form.Control.Feedback type="invalid">
                  {formValidationErrors.nombre}
            </Form.Control.Feedback>
         </div>
         <div className="mb-3">
            <Form.Label>Apellido</Form.Label>
            <Form.Control
               className={`border ps-2 ${
                  formValidationErrors.apellido ? "is-invalid" : ""
               }`}
               placeholder="Apellido"
               type="text"
               value={valorApellido}
               disabled={!isInputEnabled}
               onChange={(e) => setValorApellido(e.target.value.toUpperCase())}
            >
            </Form.Control>
            <Form.Control.Feedback type="invalid">
                  {formValidationErrors.apellido}
            </Form.Control.Feedback>            
         </div>
         <div className="mb-3">
            <Form.Label>DNI</Form.Label>
            <Form.Control
                  className={`border ps-2 ${
                     formValidationErrors.dni ? "is-invalid" : ""
                  }`}
               placeholder="Dni"
               type="number"
               value={valorDni}
               disabled={!isInputEnabled}
               onChange={(e) => setValorDni(e.target.value)}
            >
            </Form.Control>
            <Form.Control.Feedback type="invalid">
                  {formValidationErrors.dni}
            </Form.Control.Feedback>             
         </div>
         <div className="mb-3">
            <Form.Group>
               <label className="form-label">Sexo</label>
               <Row>              
                  <Col xs={12} md={12}>
                     <RadioButtonGroup 
                        nombre={"sexo"} 
                        inputs={opcionesSexo}  
                        valorParametro={valorSexo} 
                        setValor={setValorSexo}                           
                        formValidationErrors={formValidationErrors}
                        disabled={!isInputEnabled}
                        />
                  </Col>
               </Row>
            </Form.Group>
         </div>

         <div className="mb-3">
            <Form.Label className="float-start">Fecha de Nacimiento</Form.Label>
               <Form.Control
                  className="border ps-2"
                  type="date" 
                  data-date="" 
                  data-date-format="DD MMMM YYYY"
                  value={valorFechaNac}
                  disabled={!isInputEnabled}
                  onChange={(e) => setFechaNacimiento(e.target.value)}
               />
         </div>
      </FormWizard.TabContent>
      <FormWizard.TabContent
         isValid={validarPasoDatosPersonales()}
         validationError={errorMessages}
         title="Datos del domicilio"
      >                
            <Row>                   
            <Col className="pr-1" xs={12} md={12}>
               <SelectEfector 
                  valorEfector={valorEfector}
                  setValorEfector={setValorEfector}
                  setValorNombreEfector={setValorNombreEfector} 
                  setLocalidadesOptions={setLocalidadesOptions}
                  handleLocalidadChange={handleLocalidadChange}
                  formValidationErrors={formValidationErrors}
                  />
               <Form.Control.Feedback type="invalid">{formValidationErrors["idEfector"]}</Form.Control.Feedback>
            </Col>
         </Row> 
         <Row>
            <Col className="pr-1" xs={12} md={12}>
               <Form.Group>
                  <label className="form-label">Localidad</label>
                  <Select 
                     value={valorLocalidadesOptions}
                     onChange={handleLocalidadChange}
                     options={localidadesOptions}
                     className={`${formValidationErrors.idLocalidad ? "is-invalid" : ""}`}
                  />
                  <Form.Control.Feedback type="invalid">{formValidationErrors.idLocalidad}</Form.Control.Feedback>
               </Form.Group>
            </Col>
         </Row>          
         <Row>                   
            <Col className="pr-1" xs={12} md={12}>
               <Form.Group>
                  <label className="form-label">Barrio</label>
                  <Select 
                     value={valorBarrioOption}                        
                     onChange={handleBarrioChange}
                     options={barriosOptions}
                     className={`${formValidationErrors.barrio ? "is-invalid" : ""}`}
                  />
                  <Form.Control.Feedback type="invalid">{formValidationErrors.barrio}</Form.Control.Feedback>
                  {/*
                  <Form.Control
                     className={`border ps-2 ${
                        formValidationErrors.barrio ? "is-invalid" : ""
                     }`}
                     placeholder="Barrio"
                     type="text"
                     value={valorBarrio}
                     onChange={(e) => setValorBarrio(e.target.value)}
                  >
                  </Form.Control>
                  */}
               </Form.Group>
            </Col>
         </Row> 
         <Row>
            <Col  className="pr-1" xs={8} md={8}>
               <Form.Group>
                  <label className="form-label text-center">Calle</label>
                  <Form.Control
                     className={`border ps-2 ${
                        formValidationErrors.calle ? "is-invalid" : ""
                     }`}
                     placeholder="Calle"
                     type="text"
                     value={valorCalle}
                     onChange={(e) => setValorCalle(e.target.value.toUpperCase())}
                  />     
               </Form.Group>
            </Col>
            <Col  className="pr-1" xs={4} md={4}>
               <Form.Group>
                  <label className="form-label text-center">Numero</label>
                  <Form.Control
                     className={`border ps-2 ${
                        formValidationErrors.numero ? "is-invalid" : ""
                     }`}
                     placeholder="Numero"
                     type="number"
                     value={valorNumero}
                     onChange={(e) => setValorNumero(e.target.value)}
                  />                                  
               </Form.Group>
            </Col>
         </Row>
         <Row>
            <Col  className="pr-1" xs={6} md={6}>
               <Form.Group>
                  <label className="form-label text-center">Mza</label>
                  <Form.Control
                     className={`border ps-2 ${
                        formValidationErrors.mza ? "is-invalid" : ""
                     }`}
                     placeholder="Mza"
                     type="text"
                     value={valorManzana}
                     onChange={(e) => setValorManzana(e.target.value)}
                  />     
               </Form.Group>
            </Col>
            <Col  className="pr-1" xs={6} md={6}>
               <Form.Group>
                  <label className="text-center text-center">Lote</label>
                  <Form.Control
                     className={`border ps-2 ${
                        formValidationErrors.lote ? "is-invalid" : ""
                     }`}
                     placeholder="Lote"
                     type="text"
                     value={valorLote}
                     onChange={(e) => setValorLote(e.target.value)}
                  />  
               </Form.Group>
            </Col>
         </Row>
         <Row>                   
            <Col className="pr-1" xs={12} md={12}>
               <Form.Group>
                  <label className="form-label">Sector</label>
                  <Form.Control
                     className={`border ps-2 ${
                        formValidationErrors.sector ? "is-invalid" : ""
                     }`}
                     placeholder="Sector"
                     type="text"
                     value={valorSector}
                     onChange={(e) => setValorSector(e.target.value)}
                  >
                  </Form.Control>
               </Form.Group>
            </Col>
         </Row>
         <Row>                   
            <Col className="pr-1" xs={4} md={4}>
               <Form.Group>
                  <label className="form-label">Torre</label>
                  <Form.Control
                     className={`border ps-2 ${
                        formValidationErrors.torre ? "is-invalid" : ""
                     }`}
                     placeholder="Torre"
                     type="text"
                     value={valorTorre}
                     onChange={(e) => setValorTorre(e.target.value)}
                  >
                  </Form.Control>
               </Form.Group>
            </Col>
            <Col  className="pr-1" xs={4} md={4}>
               <Form.Group>
                  <label className="form-label text-center">Piso</label>
                  <Form.Control
                     className={`border ps-2 ${
                        formValidationErrors.piso ? "is-invalid" : ""
                     }`}
                     placeholder="Piso"
                     type="text"
                     value={valorPiso}
                     onChange={(e) => setValorPiso(e.target.value)}
                  />       
               </Form.Group>
            </Col>
            <Col  className="pr-1" xs={4} md={4}>
               <Form.Group>
                  <label className="form-label text-center">Dpto</label>
                  <Form.Control
                     className={`border ps-2 ${
                        formValidationErrors.dpto ? "is-invalid" : ""
                     }`}
                     placeholder="Dpto"
                     type="text"                                
                     value={valorDepto}
                     onChange={(e) =>setValorDetpo(e.target.value)}
                  />     
               </Form.Group>
            </Col>   
         </Row>
         <Row>                   
            <Col className="pr-1" xs={12} md={12}>
               <Form.Group>
                  <label className="form-label">Entre Calle</label>
                  <Form.Control
                     className={`border ps-2 ${
                        formValidationErrors.calle_1 ? "is-invalid" : ""
                     }`}
                     placeholder="Calle 1"
                     type="text"
                     value={valorCalle_1}
                     onChange={(e) => setValorCalle_1(e.target.value.toUpperCase())}
                  >
                  </Form.Control>
               </Form.Group>
            </Col>
            <Col  className="pr-1" xs={12} md={12}>
               <Form.Group>
                  <label className="form-label text-center">y Calle</label>
                  <Form.Control
                     className={`border ps-2 ${
                        formValidationErrors.calle_2 ? "is-invalid" : ""
                     }`}                     
                     placeholder="Calle 2"
                     type="text"
                     value={valorCalle_2}
                     onChange={(e) => setValorCalle_2(e.target.value.toUpperCase())}
                  />
               </Form.Group>
            </Col>
         </Row>
         
         {geoLocalizar && <GeoLocalizacion setLatitudLongitud={setLatitudLongitud} setGeoLocalizar={setGeoLocalizar} />}
         
         { valorLatitudLongitud.length !== 0 ?
               <>
                  <Row className="pt-4">
                     <Col>
                        <span className="p-3 mt-3 border border-success rounded-3">
                           Geolocalización obtenida
                        </span>
                     </Col>
                  </Row>               
                  <Form.Control
                     type="hidden" 
                     value={valorLatitudLongitud[0].toString()}
                     />
                  <Form.Control
                     type="hidden" 
                  
                     value={valorLatitudLongitud[1].toString()}
                     />
               </>
            :
            <>
               <Row className="pt-4">
                  <Col className="text-center">
                     <button className="btn btn-outline-secondary" onClick={() => setGeoLocalizar(true)}>Geolocalizar</button>
                  </Col>
               </Row>
            </>
         }
      </FormWizard.TabContent>
      <FormWizard.TabContent
         isValid={validarPasoDatosDomicilio()}
         validationError={errorMessages}
         title="Tomas de presion"
      >                
            <Row>                   
         </Row> 
         <Row>                   
            <Col className="pr-1" xs={12} md={12}>
               <Form.Group>
                  <label className="form-label">Valores de presión primer toma</label>
                  <InputMask 
                        className={`border ps-2 form-control ${
                        formValidationErrors.presion1 ? "is-invalid" : ""
                     }`}
                     mask='999/999' 
                     value={valorPresion1} 
                     onChange={(e) => { setValorPresion1(e.target.value);}}>
                  </InputMask>
                  <Form.Control.Feedback type="invalid">{formValidationErrors["presion1"]}</Form.Control.Feedback>
               </Form.Group>
            </Col>
         </Row>            
         <Row>
            <Col  className="pr-1" xs={12} md={12} >
               <Form.Group>
                  <label className="form-label text-center">Valores de presión segunda toma</label>
                  <InputMask 
                        className={`border ps-2 form-control ${
                        formValidationErrors.presion1 ? "is-invalid" : ""
                     }`}
                     mask='999/999' 
                     value={valorPresion2} 
                     onChange={(e) => { setValorPresion2(e.target.value);}}>
                  </InputMask>
                  <Form.Control.Feedback type="invalid">{formValidationErrors["presion2"]}</Form.Control.Feedback>
               </Form.Group>
            </Col>
            </Row>           
            <Row>
               <Col>
                  <div >
                     <Button style={{marginTop: '10px'}}
                        padding-top="1rem" variant="secondary" 
                        onClick={() => { 
                        setModalShowP(true);
                        }}>Como tomar la presion</Button> 
                  </div>
               <ModalPresion  show={modalShowP} onHide={ () => setModalShowP(false)} />
               </Col>
            </Row>
      </FormWizard.TabContent>
      <FormWizard.TabContent 
         className="encuesta-div h-12rem align-items-center d-flex-block"
         isValid={validarPasoDatosPresion()}
         validationError={errorMessages}
         title={preguntaFamiliar}
      >
         <Row>
            <RadioButtonGroup 
               nombre={"familiar"}
               pregunta={preguntaFamiliar}
               inputs={opcionesFamiliar}
               value={valorFamiliar}
               setValor={setValorFamiliar} 
               formValidationErrors={formValidationErrors}
               />
         </Row>       
      </FormWizard.TabContent>
      <FormWizard.TabContent 
         className="encuesta-div align-items-center d-flex-block"
         isValid={checkValidateTab("familiar", valorFamiliar, opcionesFamiliar)}
         validationError={errorMessages}            
         title={preguntaPerimetro}
      >
         <Row>
         <RadioButtonGroup 
            nombre={"perimetro"}
            pregunta={preguntaPerimetro}
            inputs={opcionesPerimetro}
            valor={valorPerimetro}
            setValor={setValorPerimetro} 
            formValidationErrors={formValidationErrors}
            />
         </Row>     
      </FormWizard.TabContent>
      <FormWizard.TabContent className="encuesta-div align-items-center d-flex-block"
         isValid={checkValidateTab("perimetro", valorPerimetro, opcionesPerimetro)}
         validationError={errorMessages}
         title={preguntaActividad}
      >
         <Row className="col-12">
         <RadioButtonGroup 
            nombre={"actividad"} 
            pregunta={preguntaActividad} 
            inputs={opcionesActividad}   
            value={valorActividad} setValor={setValorActividad}                
            formValidationErrors={formValidationErrors}
            />
         </Row>        
      </FormWizard.TabContent>
      <FormWizard.TabContent 
         className="encuesta-div align-items-center d-flex-block" 
         isValid={checkValidateTab("actividad", valorActividad, opcionesActividad)}
         validationError={errorMessages}
         title={preguntaVerduras}
      >
         <Row>
         <RadioButtonGroup 
               nombre={"verduras"}
               pregunta={preguntaVerduras}
               inputs={opcionesVerduras}
               value={valorVerdura}
               setValor={setValorVerdura} 
               formValidationErrors={formValidationErrors}
               />
         </Row>     
      </FormWizard.TabContent>
      <FormWizard.TabContent 
         isValid={checkValidateTab("verduras", valorVerdura, opcionesVerduras)}
         validationError={errorMessages}
         className="encuesta-div align-items-center d-flex-block" 
         title={preguntaMedicamentoHipertension}
      >
         <Row className="col-12">
               <RadioButtonGroup 
                  nombre={"medicamento_hipertension"}
                  pregunta={preguntaMedicamentoHipertension}
                  inputs={opcionesMedicamentoHipertension}
                  value={valorMedicamentoHipertension}
                  setValor={setValorMedicamentoHipertension} 
                  formValidationErrors={formValidationErrors}
                  />
         </Row>        
      </FormWizard.TabContent>
      <FormWizard.TabContent 
         isValid={checkValidateTab("medicamento_hipertension", valorMedicamentoHipertension, opcionesMedicamentoHipertension)}
         validationError={errorMessages}
         className="encuesta-div align-items-center d-flex-block"
         title={preguntaGlucosa}
      >
         <Row>
               <RadioButtonGroup 
                  nombre={"glucosa"}
                  pregunta={preguntaGlucosa}
                  inputs={opcionesGlucosa}
                  value={valorGlucosa}
                  setValor={setValorGlucosa} 
                  formValidationErrors={formValidationErrors}
                  />
         </Row>        
      </FormWizard.TabContent>
      <FormWizard.TabContent 
         isValid={checkValidateTab("glucosa", valorGlucosa, opcionesGlucosa)}
         validationError={errorMessages}
         title={preguntaMasa}
         className="encuesta-div align-items-center d-flex-block"
      >
         <Row>
         <RadioButtonGroup 
            nombre={"masa"} 
            pregunta={preguntaMasa} 
            inputs={opcionesMasa} 
            value={valorMasa} 
            setValor={setValorMasa} 
            formValidationErrors={formValidationErrors}
            />
         </Row>      
      </FormWizard.TabContent>
      <FormWizard.TabContent
         isValid={checkValidateTab("masa", valorMasa, opcionesMasa)}
         validationError={errorMessages}
         title="Cálculo"
      >
      <Row className="totals">
            <Col className="col-lg-6 col-12" style={{alignSelf: 'center'}}>
               <Card className="bg-gradient-primary">
                  <Card.Body>
                     <Row>
                        <Col lg="6 pt-4"><h4 className="text-white">Puntaje Total:</h4></Col><Col lg="6"><Card><CardBody><h4>{total}</h4></CardBody></Card></Col>
                     </Row>
                  </Card.Body>
               </Card> 
            </Col>
            <Col className="col-lg-6 col-12">
               <Card className="bg-gradient-success mb-2">
                  <Card.Body className="pt-2 pb-2">
                     <Row>
                        <Col>
                           <div className="text-white float-start  text-bold">Menos de <span style={{fontSize: '28px'}}>7</span> puntos</div>
                        </Col>
                        <Col>
                           <p className="text-white text-bold pt-2">Bajo riesgo</p>
                        </Col>
                     </Row>
                  </Card.Body>
               </Card>
               <Card className="bg-gradient-warning mb-2">
                  <Card.Body className="pt-3 pb-1">
                     <Row>
                        <Col className="pt-3">
                           <div className="float-start text-black-50 text-bold">de <span style={{fontSize: '28px'}}>7</span> a <span style={{fontSize: '28px'}}>12</span> Puntos</div>
                        </Col>
                        <Col className="col float-end"><p className="text-black-50 text-bold">Riego moderado<br></br>Modifique hábitos de vida<br></br>Consulte a su centro de salud</p></Col>
                     </Row>
                  </Card.Body>
               </Card>      
               <Card className="bg-gradient-danger text-white">
                  <Card.Body className="pt-3 pb-1">
                     <Row>
                        <Col>
                           <div className="float-start">mayor de <span style={{fontSize: '28px'}}>12</span> puntos</div>
                        </Col>
                        <Col>
                           <p className="text-bold">Riesgo Alto <br></br> Consultar al Médico</p>
                        </Col>
                     </Row>
                  </Card.Body>
               </Card>      
            </Col>
         </Row>  
      </FormWizard.TabContent>
      </FormWizard>
      
    </>

  );
}
